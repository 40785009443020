import { Box, Heading, Text, jsx } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import ContentPage from '~/components/Content/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import RichTextRenderer from '../components/Content/RichText'

const Lineup2022 = ({ data }) => {
  const { page } = data

  if (page) {
    const { title, heading } = page?.sections[0]
    return (
      <Layout>
        <Metadata
          title={page.metaTitle || page.title}
          description={
            page.metaDescription?.metaDescription ||
            `Super73's ${page.title} page default description`
          }
        />
        <Box sx={{ px: 2 }}>
          <Heading
            as="h1"
            sx={{
              variant: 'text.h1',
              display: 'block',
              textAlign: 'center',
              color: 'accent',
              width: ['100%', '65%'],
              margin: 'auto',
              textTransform: 'uppercase',
              letterSpacing: '0.2rem',
              fontSize: ['2.6rem', null, null, null, '4.8rem'],
              fontWeight: 'medium',
              mb: ['2.8rem', null, '2rem', null, '3rem', '5.8rem']
            }}
          >
            {title}
          </Heading>
          {heading && (
            <Heading
              as="h2"
              sx={{
                variant: 'text.h2',
                display: 'block',
                textAlign: 'center',
                color: 'accent',
                width: ['100%', '65%'],
                margin: 'auto',
                textTransform: 'uppercase',
                letterSpacing: '0.2rem',
                fontSize: ['2rem', null, null, null, '3rem'],
                fontWeight: 'medium',
                mb: ['4.8rem', null, '2rem', null, '3rem', '5.8rem']
              }}
            >
              <RichTextRenderer richText={heading} />
            </Heading>
          )}
        </Box>
        <ContentPage page={page} />
      </Layout>
    )
  } else {
    return <Layout></Layout>
  }
}

export default Lineup2022

export const query = graphql`
  query Lineup2022Page($locale: String) {
    page: contentfulPage(
      slug: { eq: "2022-lineup" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
